import React from 'react'
import TalibanStrijder from '../content/TalibanStrijder.png'

function Talibanstrijder() {
    return (
        <div className=''>
            <img src={TalibanStrijder} alt='' className='bg-cover bg-center w-full h-[91.5vh]' />
        </div>
    )
}

export default Talibanstrijder