import React from 'react'
import Ebershen from '../content/Talibanstrijder2.png'

function Ebershens() {
    return (
        <div className=''>
            <img src={Ebershen} alt='' className='bg-cover bg-center w-full h-[91.5vh]' />
        </div>
    )
}

export default Ebershens