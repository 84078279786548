import React from 'react'
import { Link } from 'react-router-dom'
import * as BS from 'react-bootstrap'


function Navbar() {

    const NavbarLink = ({ to, eventKey, children }) => (
        <BS.NavLink as={Link} to={to} eventKey>
            {children}
        </BS.NavLink>
    );

    return (
        <div className='bg-gray-900 sticky top-0 z-50'>
            <BS.Navbar expand="lg" collapseOnSelect>
                <Link className="nav-link text-5xl" to="/">Zieke Website</Link>
                <BS.Navbar.Toggle className='mr-4' style={{ backgroundColor: "#0D6EFD" }} />
                <BS.Navbar.Collapse>
                    <NavbarLink to="/talibanstrijders">TalibanStrijders</NavbarLink>
                    <NavbarLink to="/ebershens">Ebershens</NavbarLink>
                    {/* <NavbarLink to="/an1lox">An1lox</NavbarLink> */}
                </BS.Navbar.Collapse>
            </BS.Navbar>
        </div>
    )
}


export default Navbar