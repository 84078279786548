import React from 'react';
import Navbar from './components/Navbar';
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Home from './pages/Home';
import Talibanstrijder from './pages/Talibanstrijder';
import Ebershens from './pages/Ebershens';
import An1lo from './pages/An1lo';

function App() {
  return (
    <div className=''>
      <Router>
        <Navbar />
        <Routes>
          <Route exath path='/' element={<Home />} />
          <Route exath path='/talibanstrijders' element={<Talibanstrijder />} />
          <Route exath path='/ebershens' element={<Ebershens />} />
          {/* <Route exath path='/an1lox' element={<An1lo />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
