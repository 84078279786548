import React, { useState } from 'react'
import gekkeman from '../content/gekkeman.gif'

function Home() {

    const [recepIvedik] = useState('https://steamuserimages-a.akamaihd.net/ugc/939446378123915358/A07D7638B432515AA56FC83F3E06CD7A70CA84F0/' +
        '? imw = 5000 & imh=5000 & ima=fit & impolicy=Letterbox & imcolor=% 23000000 & letterbox=false');
    const [recepIvedik2] = useState('https://i.makeagif.com/media/2-18-2015/S3dLIt.gif');
    const [aliUsta] = useState('https://thumbs.gfycat.com/UglyPastGreathornedowl-max-1mb.gif');
    const [aliUsta2] = useState(gekkeman);

    return (
        <div>
            <div className='container mt-2'>
                <div className='grid grid-cols-2 gap-2 mb-1 mb:grid-cols-1 hover:'>
                    <img className='gifs' src={recepIvedik} alt='' />
                    <img className='gifs' src={aliUsta} alt='' />
                    <img className='gifs' src={recepIvedik2} alt='' />
                    <img className='gifs' src={aliUsta2} alt='' />
                </div>
            </div>
        </div>
    )
}

export default Home